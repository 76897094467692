import { Component } from 'react'
import DateRange from '../components/DateRange'
import SkrSingleDropdownList from '../components/SkrSingleDropdownList'
import SkrSingleDropdownRange from '../components/SkrSingleDropdownRange'
import SkrResultCount from '../components/SkrResultCount'
import SkrDataController from '../components/SkrDataController'
import SkrSearchFunnel from '../components/SkrSearchFunnel'
import SkrReactiveBase from '../components/SkrReactiveBase'
import initReactivesearch from '../server';
import { getTeaserConfig } from '../components/config'
import { getSettings } from '../common/settings'

const defaultState = {result_type: ''}

export default class App extends Component {

  static async getInitialProps({ query }) {
    const initialState = {
      ...defaultState
    }

    const components = getTeaserConfig(initialState)

    return {
      initialState: initialState,
      store: await initReactivesearch([{
          ...components.countries, type: 'SkrSingleDropdownList', source: SkrSingleDropdownList,
        }, {
          ...components.trip_type, type: 'SkrSingleDropdownList', source: SkrSingleDropdownList,
        }, {
          ...components.date_range, type: 'DateRange', source: DateRange,
        }, {
          ...components.availability, type: 'SkrDataController', source: SkrDataController,
        }, {
          ...components.result_type, type: 'SkrDataController', source: SkrDataController,
        }, {
          ...components.result_count, type: 'SkrResultCount', source: SkrResultCount,
        }, {
          ...components.search_funnel, type: 'SkrSearchFunnel', source: SkrSearchFunnel,
        }], initialState, getSettings()),
    }
  }

  constructor(props) {
    const { initialState } = props;
    super(props);
    this.state = initialState;
  }

  render () {
    const components = getTeaserConfig(this.state)

    return (<SkrReactiveBase {...getSettings()} initialState={this.props.store}>
        <div className="search-facets">
          <SkrSingleDropdownList className="search-facet" {...components.countries}  />
          <DateRange className="search-facet" {...components.date_range} />
          <SkrDataController {...components.availability} />
          <SkrDataController {...components.result_type} />
          <SkrResultCount {...components.result_count} />
          <SkrSearchFunnel components={components} {...components.search_funnel} />
        </div>
    </SkrReactiveBase>)
  }
}
