import { Component } from 'react';

import { getClassName } from '@appbaseio/reactivecore/lib/utils/helper';
import types from '@appbaseio/reactivecore/lib/utils/types';
import SkrReactiveList from './SkrReactiveList';

class SkrResultCount extends Component {

  static generateQueryOptions = props => SkrReactiveList.generateQueryOptions(props)

  constructor (props) {
    super(props)
    this.state = {result_type: 'trip'}
  }

  onAllData() {
    return null
  }

  onResultStats = (total) => (<p data-num-found>{'' + total + ' Reisen stehen zu Ihrer Auswahl'}</p>)

  render() {
    const { onAllData, onNoResults, onResultStats, innerClass, size, ...props } = this.props;
    return (
      <SkrReactiveList
        {...props}
        size={0}
        innerClass={{'noResults': 'hide'}}
        onAllData={this.onAllData}
        onResultStats={this.onResultStats} />
    );
  }
}

SkrResultCount.propTypes = {
  innerClass: types.style,
  dataField: types.stringRequired,
  target: types.stringRequired,
  onAllData: types.func,
  onResultStats: types.func,
  resultType: types.stringRequired
};

SkrResultCount.defaultProps = {
  target: '_blank',
  dataField: '_score',
  resultType: 'trip',
  pagination: false,
};

export default SkrResultCount
